import * as React from 'react';
import { graphql, Link } from 'gatsby';

// components
import Asset from '../components/asset/Asset';
import Seo from '../components/seo/SEO';

// svgs
import Plus from '../svgs/circle-plus.svg';

// utils
import { renderSwitch } from '../utils/Render';

const PostPage = ({
    data: {
        posts: { nodes: posts },
        page,
    },
    location,
}) => {
    const post = page.richTextStandard;
    const content = page.contentMatrix;

    const { seo } = page;
    const keywords = [];
    if (seo.keywords) {
        seo.keywords.forEach(keyword => {
            keywords.push(keyword.keyword);
        });
    }

    const postsEl = posts.map(postEntry => (
        <li key={postEntry.id} className="relative list-style-none">
            <Link to={`/${postEntry.uri}`}>
                {postEntry.singleAsset[0] ? (
                    <div style={{ height: '240px' }} className="m-24">
                        <Asset
                            border
                            data={postEntry.singleAsset[0].imageFile}
                            title={postEntry.singleAsset[0].title}
                        />
                    </div>
                ) : null}

                <h3 className="h3">{postEntry.title}</h3>
                {postEntry.richTextSimple ? (
                    <div dangerouslySetInnerHTML={{ __html: postEntry.richTextStandard }} />
                ) : null}
                <Plus className="overlay-button overlay-button--offset" role="button" />
            </Link>
        </li>
    ));

    return (
        <>
            <Seo
                title={seo.title}
                canonical={seo.advanced.canonical}
                description={seo.description}
                facebookImage={seo.social.facebook.image?.url}
                keywords={keywords}
                robots={seo.advanced.robots}
                twitterImage={seo.social.twitter.image?.url}
                modifiedTime={page.dateUpdated}
                url={location.href}
            />
            <div className="spacing--small">
                <div className="flex wrap justify-center relative">
                    {page.singleAsset[0] ? (
                        <div className="m-42">
                            <Asset
                                border
                                data={page.singleAsset[0].imageFile}
                                title={page.singleAsset[0].title}
                            />
                        </div>
                    ) : null}

                    <h1 className="h1">{page.title}</h1>

                    <div
                        className="rich-text rich-text--no-list-columns constrain-width m-auto m-64"
                        dangerouslySetInnerHTML={{ __html: post }}
                    />
                </div>

                {content.map((block, i) => renderSwitch(block, i))}

                <div className="flex wrap justify-between align-center">
                    {posts.length > 0 ? <h2 className="h2">Latest Blog Posts</h2> : null}
                </div>

                <div className="grid grid--3">{postsEl}</div>
            </div>
        </>
    );
};

export const query = graphql`
    query ($id: IDQueryOperatorInput) {
        posts: allCraftBlogBlogEntry(limit: 3) {
            nodes {
                id: remoteId
                title
                typeHandle
                uri
                richTextSimple
                blogPostTagField {
                    title
                }
                singleAsset {
                    ... on Craft_images_Asset {
                        id: remoteId
                        imageFile {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED)
                            }
                        }
                    }
                    url
                    title
                }
            }
        }
        page: craftBlogBlogEntry(remoteId: $id) {
            id: remoteId
            title
            uri
            ... on Craft_blog_blog_Entry {
                seo {
                    title
                    description
                    keywords {
                        keyword
                    }
                    advanced {
                        robots
                        canonical
                    }
                    social {
                        twitter {
                            image {
                                ... on Craft_images_Asset {
                                    url
                                }
                            }
                        }
                        facebook {
                            image {
                                ... on Craft_images_Asset {
                                    url
                                }
                            }
                        }
                    }
                }
                singleAsset {
                    ... on Craft_images_Asset {
                        id
                        imageFile {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED)
                            }
                        }
                    }
                    url
                    title
                }
                dateUpdated
                richTextStandard
                contentMatrix {
                    ...RichTextFragment
                    ...ProductsBlockFragment
                    ...TextGridBlockFragment
                    ...CalloutCtaBlockFragment
                    ...LargeCalloutBlockFragment
                    ...RainyDaysCalloutFragment
                    ...SingleMediaFragment
                    ...TextAndImageGridBlockFragment
                    ...ThumbnailImageGridFragment
                    ...TwoUpImageBlockFragment
                    ...ImageCarouselFragment
                    ...TextAndImageCtaBlockFragment
                    ...NewsletterSignupFragment
                    ...TextImageBlockFragment
                    ...PressBlockFragment
                    ...BannerBlockFragment
                    ...TimelineBlockFragment
                    ...FigureLinkGridFragment
                    ...ContactFormFragment
                    ...CloudGraphic
                    ...RainGraphic
                    ...StoremapperEmbed
                }
            }
        }
    }
`;

export default PostPage;
